@import '~quasar/src/css/variables';

.q-link {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.line-break {
  white-space: pre-line;
}

.menu-bg {
  background: #1A2E44;
}

.text-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.margin-center{
  margin: 0 auto;
}

.q-field__native.required ~ .q-field__label:after{
  content: ' *';
  color: red;
}

.q-field.required {
  .q-field__label:after{
    content: ' *';
    color: red;
  }
}

.group_field{
  padding: 5px;
}
